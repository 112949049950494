.outer-container {
  height: 100%;
  display: grid;
  place-items: center;
  justify-content: center;
}


.social-buttons-container {
  // margin: 0px 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
