$animation-time: 10s;


.photo-carousel-container {
  height: 100%;
  width: 100%;
  position: relative;
  
}

.carousel{
  height: 100%;
  width: 100%;
}

.carousel-inner {
  height: 100%;
  width: 100%;
}

.c-item {
  height: 100%;
  width: 100%;
}

.control {
  // opacity: 60%;

  animation: control_breathing $animation-time ease 0s infinite alternate;
  -webkit-animation: control_breathing $animation-time ease 0s infinite alternate;
  -moz-animation: control_breathing $animation-time ease 0s infinite alternate;
  -o-animation: control_breathing $animation-time ease 0s infinite alternate;
}

@keyframes control_breathing {
  0% {
    opacity: 10%;
  }
  50% {
    opacity: 40%;
  }
  100% {
    opacity: 10%;
  }
}


.c-img {
  filter:brightness(0.75);
  height: 100dvh;
  // width: 100%;
  // position: absolute;
  // align-items: center;
  // top: 50%;
  // left: 10%;
  // transform: translate(-50%, -50%);
  object-fit: cover;
  // object-position: 10% 10%;

  
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;


  animation: blur_slider_transition $animation-time ease 0s infinite;
  -webkit-animation: blur_slider_transition $animation-time ease 0s infinite;
  -moz-animation: blur_slider_transition $animation-time ease 0s infinite;
  -o-animation: blur_slider_transition $animation-time ease 0s infinite;
}


@keyframes blur_slider_transition {
  0% {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: grayscale(1) brightness(0) saturate(0);
    
    object-position: 25% 25%;
  }

  25% {
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -o-filter: blur(0px);
    -ms-filter: blur(0px);
    filter: grayscale(0) brightness(0.75) saturate(1);
  }

  75% {
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -o-filter: blur(0px);
    -ms-filter: blur(0px);
    filter: grayscale(0) brightness(0.75) saturate(1);
  }

  100% {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: grayscale(1) brightness(0) saturate(0);
    
    object-position: 75% 75%;
  }
};

