// Title Card
$title-card-animation-time: 5s;

//  Title Related
$animation-time: 7s;

$title-least-opacity: 70%;
$title-mild-opacity: 80%;

$title-highest-blur: 2px;
$title-mild-blur: 1px;

.hero-title-container {
  // --angle: 0deg;
  z-index: 10;
  position: absolute;
  
  // The following 3 lines centers the container 
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
  // The following height, weight properties sets container dimenions
  height: 50%;
  width: 50%;
  min-height: 200px;
  max-height: 300px;
  min-width: 200px;
  max-width: 500px;
  
  text-align: center;
  position: relative;

  // for frosty effect
  background-color: rgba(255, 255, 255, 0.05);
  // background: radial-gradient(rgba(0, 128, 128, 0.40), rgba(0, 128, 128, 0.10));

  // background-color: rgba(0, 128, 128, 0.75);
  backdrop-filter: blur(8px);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, .5);

  // for border
  border: 3px solid transparent;
  border-radius: 10px; // this is not working

  display: flex;
  justify-content:center;
  flex-direction:column;

  overflow: hidden;
  // mix-blend-mode: screen; /* This makes the cutout text possible */
}


.hero-title {
  // opacity: 100%;
  color: aliceblue;
  // font-family: Arial, Helvetica, sans-serif;
  font-size: 3rem;
  font-weight: 120;
  // margin-top: 0;
  // padding: 0px 20px;
  // padding-top: 20px;
  // padding-bottom: 10px;
  line-height: 100%;

  // backdrop-filter: none;
}


.hero-title > p {
  margin-bottom: 0px;
  margin-top: 0px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  backface-visibility: hidden;
}



.title-part1 {
  animation: t-part1 $animation-time linear infinite;
  -moz-animation: t-part1 $animation-time linear infinite;
  /* Firefox */
  -webkit-animation: t-part1 $animation-time linear infinite;
  /* Safari and Chrome */
  -o-animation: t-part1 $animation-time linear infinite;
  /* Opera */
}

@keyframes t-part1 {
  0% {
    opacity: 100%;
    // transform: scale(1.05);
    text-shadow:
      0 0 20px #fff,
      0 0 30px #fff
    ;
    filter: blur(0px);
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -o-filter: blur(0px);
  }
  25% {
    text-shadow:
      0 0 20px #fff
    ;
    filter: blur($title-mild-blur);
    -webkit-filter: blur($title-mild-blur);
    -moz-filter: blur($title-mild-blur);
    -o-filter: blur($title-mild-blur);
  }
  50% {
    opacity: $title-least-opacity;
    // transform: scale(0.95);
    text-shadow: none;
    filter: blur($title-highest-blur);
    -webkit-filter: blur($title-highest-blur);
    -moz-filter: blur($title-highest-blur);
    -o-filter: blur($title-highest-blur);
  }
  75% {
    text-shadow:
      0 0 20px #fff
    ;
    filter: blur($title-mild-blur);
    -webkit-filter: blur($title-mild-blur);
    -moz-filter: blur($title-mild-blur);
    -o-filter: blur($title-mild-blur);
  }
  100% {
    opacity: 100%;
    // transform: scale(1.05);
    text-shadow:
      0 0 20px #fff,
      0 0 30px #fff
    ;
    filter: blur(0px);
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -o-filter: blur(0px);
  }
}



.title-part2 {
  animation: t-part2 $animation-time linear infinite;
  -moz-animation: t-part2 $animation-time linear infinite;
  /* Firefox */
  -webkit-animation: t-part2 $animation-time linear infinite;
  /* Safari and Chrome */
  -o-animation: t-part2 $animation-time linear infinite;
  /* Opera */
}


@keyframes t-part2 {
  0% {
    opacity: $title-mild-opacity;
    // transform: scale(0.95);
    text-shadow:
      0 0 20px #fff
    ;
    filter: blur($title-mild-blur);
    -webkit-filter: blur($title-mild-blur);
    -moz-filter: blur($title-mild-blur);
    -o-filter: blur($title-mild-blur);
  }
  16.6666666667% {
    text-shadow:
      0 0 20px #fff,
      0 0 30px #fff
    ;
    filter: blur(0px);
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -o-filter: blur(0px);
  }
  25% {
    opacity: 100%;
    // transform: scale(1);
  }
  33.3333333334% {
    text-shadow:
      0 0 20px #fff,
      0 0 30px #fff
    ;
    filter: blur($title-highest-blur);
    -webkit-filter: blur($title-highest-blur);
    -moz-filter: blur($title-highest-blur);
    -o-filter: blur($title-highest-blur);
  }
  50% {
    opacity: $title-mild-opacity;
    // transform: scale(1);
    text-shadow:
      0 0 20px #fff
    ;
    filter: blur($title-mild-blur);
    -webkit-filter: blur($title-mild-blur);
    -moz-filter: blur($title-mild-blur);
    -o-filter: blur($title-mild-blur);
  }
  66.6666666668% {
    text-shadow:
      0 0 20px #fff,
      0 0 30px #fff
    ;
    filter: blur(0px);
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -o-filter: blur(0px);
  }
  75% {
    opacity: 100%;
    // transform: scale(1);
  }
  83.3333333335% {
    text-shadow:
      0 0 20px #fff,
      0 0 30px #fff
    ;
    filter: blur(0px);
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -o-filter: blur(0px);
  }
  100% {
    opacity: $title-mild-opacity;
    // transform: scale(0.95);
    text-shadow:
      0 0 20px #fff
    ;
    filter: blur($title-mild-blur);
    -webkit-filter: blur($title-mild-blur);
    -moz-filter: blur($title-mild-blur);
    -o-filter: blur($title-mild-blur);
  }
}


.title-part3 {
  // font-size: 32px;
  animation: t-part3 $animation-time linear infinite;
  -moz-animation: t-part3 $animation-time linear infinite;
  /* Firefox */
  -webkit-animation: t-part3 $animation-time linear infinite;
  /* Safari and Chrome */
  -o-animation: t-part3 $animation-time linear infinite;
  /* Opera */
}

@keyframes t-part3 {
  0% {
    opacity: $title-least-opacity;
    // transform: scale(0.95);
    text-shadow: none;
    filter: blur($title-highest-blur);
    -webkit-filter: blur($title-highest-blur);
    -moz-filter: blur($title-highest-blur);
    -o-filter: blur($title-highest-blur);
  }
  25% {
    opacity: $title-mild-opacity;
    text-shadow:
      0 0 20px #fff
    ;
    filter: blur($title-mild-blur);
    -webkit-filter: blur($title-mild-blur);
    -moz-filter: blur($title-mild-blur);
    -o-filter: blur($title-mild-blur);
  }
  50% {
    opacity: 100%;
    // transform: scale(1.05);
    text-shadow:
      0 0 20px #fff,
      0 0 30px #fff
    ;
    filter: blur(0px);
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -o-filter: blur(0px);
  }
  75% {
    opacity: $title-mild-opacity;
    text-shadow:
      0 0 20px #fff
    ;
    filter: blur($title-mild-blur);
    -webkit-filter: blur($title-mild-blur);
    -moz-filter: blur($title-mild-blur);
    -o-filter: blur($title-mild-blur);
  }
  100% {
    opacity: $title-least-opacity;
    // transform: scale(0.95);
    text-shadow: none;
    filter: blur($title-highest-blur);
    -webkit-filter: blur($title-highest-blur);
    -moz-filter: blur($title-highest-blur);
    -o-filter: blur($title-highest-blur);
  }
}