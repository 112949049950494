.icon {
  --width: 75px;
  width: var(--width);
  height: var(--width);
  // color: #fff;
  // font-size: 30px;
  border-radius: 20px;

  background: rgba(226, 168, 168, 0.05);
  backdrop-filter: blur(2px);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.05);
  text-decoration: none;
  margin: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.8;
}


.icon:hover {
  transform: scale(1.05);
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.1);
  &:before {
    transform: skewX(45deg) translateX(calc(var(--width) * -1 - 50%));
  }
}

.icon:active {
  transform: scale(0.9);
  box-shadow: 0 1px 1px rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.15);
}


.svg_image {
  scale: 2;
  filter: brightness(25%) contrast(80%);
  filter: invert(100%) sepia(9%) saturate(76%) hue-rotate(316deg) brightness(112%) contrast(92%);
}